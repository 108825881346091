.container {
  width: 100%;

  @media (--s) {
    display: block;
    position: relative;
    z-index: 1;
    width: 700px;
    height: var(--ds-space-8-x);
    background-color: var(--cds-color-monochrome-white);
    border-radius: 50px;
    color: var(--cds-color-monochrome-dark-grey);
  }

  [data-field] {
    margin-bottom: -12px;
    @media (--s) {
      margin-bottom: 0;
    }
  }
}

.form {
  @media (--s) {
    display: flex;
    width: 700px;
    height: var(--ds-space-8-x);
    align-items: center;
    padding: 0 10px 0 var(--ds-space-4-x);
  }
}

.buttonContainer {
  @media (--s) {
    width: 210px;
    margin-left: var(--ds-space-3-x);
  }
}

.button {
  margin-top: calc(var(--ds-space-half-x) / 2);
  @media (--s) {
    min-width: 210px;
    max-width: 210px;
    padding: 0 !important;
    margin-top: 0;
  }
}

.selectDivide {
  @media (--s) {
    height: 34px;
    border-left: solid var(--ds-color-asphaltGrey) 1px;
    margin-right: var(--ds-space-4-x);
    margin-left: var(--ds-space-3-x);
  }
}

/* DS SelectField overrides */
.selectContainer {
  @media (--s) {
    height: 75%;
    margin: 0;
    cursor: pointer;
  }
}

.select {
  @media (--s) {
    position: relative;
    width: 182.5px;
    padding: 0;
    border: none;
    background-color: var(--cds-color-monochrome-white) !important;
    color: var(--cds-color-monochrome-dark-grey) !important;
    outline: none;
  }
}

.select:disabled {
  @media (--s) {
    color: var(--ds-color-haze) !important;
  }
}

.arrow {
  @media (--s) {
    right: 0;
  }
}
