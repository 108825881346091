.container {
  position: fixed;
  right: 0;
  top: 75vh;
  z-index: 2;
}

.button {
  box-shadow: 0 0 8px var(--ds-color-asphaltGrey);
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: var(--ds-color-vividViolet);
  color: var(--ds-color-pureWhite);
  padding: var(--ds-space-1-x);
  font-size: var(--ds-font-size-xs);
  cursor: pointer;
}

.text {
  text-align: center;
  margin-bottom: var(--ds-space-3-x);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: var(--ds-space-1-x);
  padding: 0 var(--ds-space-2-x);
  text-align: center;
}
