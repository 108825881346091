.trustpilotScore {
  margin-top: var(--ds-space-4-x);
}

.heading {
  color: var(--ds-color-deepPurple);
  font-weight: var(--ds-font-weight-bold);
  line-height: var(--ds-space-5-x);
  margin-bottom: var(--ds-space-3-x);
  margin-top: var(--ds-space-4-x);
  font-size: var(--ds-font-size-3xl);

  @media (--s) {
    margin-bottom: var(--ds-space-4-x);
    font-size: var(--ds-font-size-4xl);
  }
}

.valuePropsCardsContainer {
  display: grid;
  margin-top: 50px;
  grid-row-gap: var(--ds-space-6-x);

  @media (--m) {
    margin-top: var(--ds-space-7-x);
    column-gap: var(--ds-space-2-x);
    grid-template-columns: repeat(2, 1fr);
  }
}
