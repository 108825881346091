.container {
  width: 100%;
  height: 100%;
}

.link {
  width: 100%;
  height: 100%;
  cursor: pointer;

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
}

.meta {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--ds-space-5-x) var(--ds-space-3-x);
}

.imageContainer {
  position: relative;
}

.actions {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--ds-color-deepPurple);
}

.save {
  z-index: 2;
}

.unavailable {
  opacity: 0.3;
}

.deliveryImage {
  max-width: 100px;
}

.reserved {
  position: absolute;
  top: var(--ds-space-2-x);
  left: var(--ds-space-3-x);
  padding: var(--ds-space-half-x) var(--ds-space-1-x);
  background-color: var(--ds-color-haze);
  border-radius: 3px;
}

.titleCarCards {
  color: var(--ds-color-mineShaft);
}

.metaCarCards {
  min-height: 195px;
  padding: var(--ds-space-2-x);
}

.tagsCarCards {
  margin-top: 0;
}

.tagItemCarCard {
  padding: var(--ds-space-half-x) var(--ds-space-1-x);
  margin-top: var(--ds-space-1-x);
  margin-right: var(--ds-space-1-x);
  background-color: var(--ds-color-wildSand);
  border-radius: var(--ds-space-3-x);
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-xs);
  list-style-type: none;
}
