.container {
  position: relative;
  height: 100%;
  color: var(--cds-color-monochrome-white);
  -webkit-font-smoothing: antialiased;

  @media (--s) {
    margin-bottom: var(--ds-space-3-x);
  }

  @media (--m) {
    margin-bottom: var(--ds-space-4-x);
  }
}

.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 49%;
  margin-top: -82px;

  @media (--s) {
    height: 93.5%;
  }
}

/* Hacky solution to display left border curve below gradient */
.background::before {
  position: absolute;
  bottom: -50px;
  width: 20px;
  height: 50px;
  background-color: var(--ds-color-transparent);
  border-top-left-radius: 20px;
  box-shadow: 0 -20px 0 0 #d333ec;
  content: '';
}

.backgroundGradient {
  background-image: linear-gradient(
    180deg,
    #311b77 0%,
    #8f1dbf 48%,
    #d333ec 98%
  );
}

.inner {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}

.textContainer {
  max-width: 335px;
  padding: 0 var(--ds-space-1-x);
  margin-top: 6px;
  text-align: center;

  @media (--s) {
    max-width: 520px;
    padding: 0;
    margin-top: var(--ds-space-4-x);
    margin-bottom: var(--ds-space-2-x);
  }

  @media (--m) {
    max-width: 970px;
  }
}

.heading {
  margin-bottom: var(--ds-space-1-x);
  font-size: var(--ds-font-size-3xl);
  font-weight: var(--ds-font-weight-bold);
  line-height: var(--ds-space-5-x);

  @media (--s) {
    margin-bottom: var(--ds-space-1-and-a-half-x);
  }

  @media (--m) {
    font-size: var(--ds-font-size-4xl);
    line-height: var(--ds-space-7-x);
  }
}

.intro {
  font-size: var(--ds-font-size-m);
  font-weight: var(--ds-font-weight-medium);
  line-height: var(--ds-font-line-height-body);

  @media (--m) {
    font-size: var(--ds-font-size-l);
  }
}

.flexDirection {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--s) {
    flex-direction: column-reverse;
  }
}

.imageContainer {
  position: relative;
  width: 320px;
  height: 106px;
  margin-top: var(--ds-space-1-and-a-half-x);

  @media (--s) {
    width: 434px;
    height: 144px;
    margin-top: var(--ds-space-3-x);
  }

  @media (--l) {
    width: 553px;
    height: 184px;
  }
}

.filtersHmcContainer {
  width: 100%;
  max-width: 325px;
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-left: calc(16px + env(safe-area-inset-left));
  margin-right: auto;
  margin-left: auto;

  @media (--s) {
    padding: 0;
    margin: 0;
    max-width: 100%;
  }
}

.filtersHmcInner {
  padding: 30px 20px 25px;
  margin-bottom: 45px;
  border-radius: var(--ds-space-3-x);
  box-shadow: 0 15px 30px rgb(169 169 169 / 30.2%);

  @media (--xxxs) {
    padding: 30px 25px 25px;
  }

  @media (--s) {
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
  }
}

.hmcContainer {
  margin-top: var(--ds-space-3-x);
  text-align: center;

  @media (--s) {
    margin-top: var(--ds-space-2-x);
  }
}

.hmcText {
  color: var(--cds-color-primary-deep-purple);
  font-size: var(--ds-font-size-m);

  @media (--s) {
    color: var(--cds-color-monochrome-white);
    font-size: var(--ds-font-size-s);
  }
}

.hmcText span:first-of-type::after {
  /* Break to new line after first paragraph */
  content: '\a';
  white-space: pre;

  @media (--s) {
    content: ' ';
  }
}

.hmcLink {
  color: var(--ds-color-toreaBay);
  text-decoration: underline;

  @media (--s) {
    font-size: var(--ds-font-size-s);
    color: var(--cds-color-monochrome-white);
    font-weight: var(--ds-font-weight-semibold);
  }
}
